import React from 'react';
import UserBooks from './UserBooks';
import RTL from "./RTL"

function App() {
	
	return (
		<RTL>
			<UserBooks />
		</RTL>
	);
}

export default App;
