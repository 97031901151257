import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SearchField from './SearchField';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CameraIcon from '@material-ui/icons/CameraAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import MoreIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileIcon: {
	  paddingLeft: theme.spacing(1),
  },
}));

export default function MainBar(props) {
	const usersRef = useRef();
	const settingsRef = useRef();
	
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
	  disableRestoreFocus={true}
      onClose={handleMobileMenuClose}>
      <MenuItem>
        <Badge badgeContent={4} color="secondary">
		  <MailIcon />
        </Badge>
        <p className={classes.mobileIcon}>הודעות</p>
      </MenuItem>
      <MenuItem>
	    <Badge badgeContent={17} color="secondary">
		  <NotificationsIcon />
        </Badge>
        <p className={classes.mobileIcon}>התרעות</p>
      </MenuItem>
      <MenuItem ref={settingsRef} onClick={()=>onBtnClick(settingsRef, ()=>props.optionSelected("settings"))}>
        <SettingsIcon  />
		<p className={classes.mobileIcon}>הגדרות</p>
      </MenuItem>
    </Menu>
  );
  
  const onBtnClick = (ref, callback) => {
	if(ref.current) ref.current.setAttribute("disabled", "disabled");
	if (isMobileMenuOpen) handleMobileMenuClose();
	callback();
  }
  
  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
			ref={usersRef}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
			onClick={()=>onBtnClick(usersRef, ()=>props.onOpenDrawerClick())}>
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            ניהול ספריית בתי ספר
          </Typography>
		  <SearchField users={props.users} onStateChanged={(enter)=>props.onSearchComponent(enter)} onSelect={(id)=>props.onUserSelected(id)}/>
		  <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton ref={settingsRef} color="inherit" onClick={()=>onBtnClick(settingsRef, ()=>props.optionSelected("settings"))}>
				<SettingsIcon />
			</IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}