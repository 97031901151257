import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MainBar from './MainBar';
import Users from './Users';
import AlertDialog from './AlertDialog';
import NewBook from './NewBook';
import Settings from './Settings';
import G from './Globals.js'
import BarcodeScannerComponent from "react-webcam-barcode-scanner2";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useSnackbar } from 'notistack';

import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CloseCardIcon from '@material-ui/icons/CancelPresentation';
import ReturnIcon from '@material-ui/icons/Reply';
import CloseCameraIcon from '@material-ui/icons/HighlightOff';

import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CameraIcon from '@material-ui/icons/CameraAlt';

import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => ({
	cardroot: {
		maxWidth: 750,
		height: "100%",
		padding: theme.spacing(1),
		margin: theme.spacing(2, 'auto'),
			'& .MuiTextField-root': {
				width: '100%',
			},
	},
  
	cardheader: {
		backgroundColor: "white",
		padding: theme.spacing(1,2),
	},

	cardfotter1: {
		position: "relative",
		width: "100%",
	},

	cardfotter2: {
		position: "absolute",
		right: 0,
		bottom: 0, 
	},
  
	cameraButton: {
		position: 'fixed',
		zIndex: 100,
		left: 15,
		bottom: 35,

	},

	cameraBackground: {
		position: "absolute",
		background: "rgba(0, 0, 0, 0.7)",
		color: "white",
		top:0,
		left:0,
		width: "100%",
		height:"100%",
	},
  
	camera: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
  
	title: {
		fontSize: 17,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
  
	margin: {
		margin: theme.spacing(3, 2),
	},
  
	pos: {
		marginBottom: 0,
	},

	grid: {
		margin: theme.spacing(0, 1),
		//padding: theme.spacing(5),
	}, 
	
	booksList: {
		margin: theme.spacing(0, 'auto'),
	},

}));

export default function UserBooks() {
	const classes = useStyles();
	const usersRef = useRef();
	
	const [appMode, setAppMode] = useState("main");
	const [settingsMode, setSettingsMode] = useState(false);

	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [user, setUser] = useState();
	const [books, setBooks] = useState(null);
	
	const [bookId, setBookId] = useState("");
	const [newBookId, setNewBookId] = useState("");
	
	const [anchorEl, setAnchorEl] = useState(null);
	const [editUser, setEditUser] = useState({mode:false});
	
	const { enqueueSnackbar } = useSnackbar();

	const addBook = (uid, bid, books) => {
		fetch(`${G.baseUrl}/isBookAvaliable.php?uid=${uid}&bid=${bid}`)
			.then(res => res.json())
			.then((res)=>{
				if (res.status === "avaliable") {
					if (res.book.state === "new") setBooks([...books, res.book]);
					else if (res.book.state==="deleted") setBooks(books.filter((book) => (book.id !== res.book.id)));
					else {
						setBooks(books.map((book, index) => { 
							return (book.id === res.book.id) ? {...book, state: res.book.state} : book;
						}));
					}
					if (res.book.state === "new" || res.book.state === "return") enqueueSnackbar("הפעולה הסתיימה בהצלחה", { variant: 'success',});
				} else {
					var variant = (res.variant)?res.variant:"error";
					enqueueSnackbar(res.errorMsg, { variant: variant,});
					if (res.status === "noExist") addNewBook(bid);
				}
			})
			.catch()
	}
	
	const addNewBook = (bookId) => {
		setNewBookId(bookId);
		setAppMode("newBook");
	};
	
	const reloadUser = async (params) => {
		var result = null;
		
		await fetch(`${G.baseUrl}/user.php?${params}`)
			.then(res => res.json())
			.then(res => {
				if (res.status === "ok") {
					setUser(res.user);
					setBooks(res.books);
					setEditUser({mode: false, name: res.user.name, grade: res.user.grade, gender: res.user.gender});
				} else {
					var variant = (res.variant)?res.variant:"error";
					enqueueSnackbar(res.errorMsg, { variant: variant,});
				}
				result = res;
			});
		setAppMode("main");	
		return result;
	};
	
	useEffect(() => {
		if (!selectedUser) {
			setUser(null);
			setBooks(null);
		} else {
			var params = "id=" + selectedUser.id;
			if (selectedUser.type==="byBook") params += "&type=book";
			reloadUser(params)
			.then (res => {
				if (selectedUser.type==="byBook") {
					if (res.status==="ok") addBook(res.user.id, selectedUser.id, res.books);
					else if (res.status==="noBook") addNewBook(selectedUser.id);
				}				
			})
		}
	}, [selectedUser]);
	
	useEffect(() => {
		console.log("app mode changed: " + appMode);
		if (appMode==="settings") setSettingsMode(true);
		else if (appMode==="main") setSettingsMode(false);
	}, [appMode]);
	
	const handleKeyEvent = (key, event) => {
		if (event.keyCode===27) handleEscKeyEvent();
		else if (appMode==="main" || appMode==="manualBook") {
			if (event.keyCode!==13) setBookId(bookId+key);
			else {
				getBook(bookId);
				setBookId("");
			}
			
		}
	};
	
	const handleEscKeyEvent = () => {
		console.log("handleKeyEvent: " + appMode);
		if  (appMode==="main") closeUserCard();
		else if (appMode==="search" || appMode==="chooseUser" || appMode==="openUserMenu" || appMode==="manualBook" || appMode==="camera" || appMode==="newBook" || appMode==="settings") setAppMode("main");
		else if (appMode==="editUser") endEditUser(false);
		else if (appMode==="deleteUser") endDeleteUser(false);
		setBookId("");
	}
	
	const getBook = (bid) => {
		if (bid.length>0) {
			if (user) addBook(user.id, bid, books);
			else setSelectedUser({"id": bid, "type": "byBook"});
		}
		 setAppMode("main");
		
	}
	
	const closeUserCard = () => {
		setAnchorEl(null);
		setSelectedUser(null);
	}

	const startEditUser = () => {
		setAnchorEl(null);
		setEditUser({...editUser, mode: true})
		setAppMode("editUser");
		console.log("appMode: "+appMode);
	};
	
	const endEditUser = (save) => {
		if (save) {
			var params = `op=edit&id=${user.id}&name=${editUser.name}&grade=${editUser.grade}&gender=${editUser.gender}`;
			reloadUser(params)
			.then(res => {
				if (res.status==="ok") {
					enqueueSnackbar("המשתמש עודכן בהצלחה!", { variant: 'success',});
					usersRef.current.reloadUsers();
				}
			})
		} else {
			setEditUser({mode: false, name: user.name, grade: user.grade, gender: user.gender});
		}
		setAppMode("main");
	};
	
	const startDeleteUser = () => {
		setAnchorEl(null);
		setAppMode("deleteUser");
	};
	
	const endDeleteUser = (execute) => {
		if (execute) {
			var params = `op=delete&id=${user.id}`;
			fetch(`${G.baseUrl}/user.php?${params}`)
			.then(()=>{
				usersRef.current.reloadUsers();
				setSelectedUser(null);
			});
		}
		setAppMode("main");
	};
	
	const endNewBook = (execute) => {
		if (execute && user) addBook(user.id, newBookId, books);
		setAppMode("main");
	}
	
	const UserSelected = (uid) => {
		setSelectedUser({"id": uid, "type": "byName"})
	};
	
	return (
		<React.Fragment>
			<KeyboardEventHandler handleKeys={['numeric', 'enter', 'esc']} handleFocusableElements={true} onKeyEvent={handleKeyEvent} />
			<AlertDialog id="AlertMsg" dialog={appMode==="deleteUser"} description={"האם אתה בטוח שברצונך למחוק את המשתמש?"} onClose={(execute)=>endDeleteUser(execute)} />
			<MainBar users={users} onOpenDrawerClick={()=>setAppMode("chooseUser")} onSearchComponent={(enter)=>enter && setAppMode("search")} onUserSelected={(uid)=>UserSelected(uid)} optionSelected={(mode)=>{console.log("option pressed: "+mode);setAppMode(mode)}} />
			<Users ref={usersRef} open={appMode==="chooseUser"} onUsersListChanged={(users)=>setUsers(users)} onUserSelected={(uid)=>UserSelected(uid)} onUsersClose={()=>setAppMode("userChosen")} />
			<NewBook id={newBookId} open={appMode==="newBook"} onClose={(execute)=>endNewBook(execute)} />
			<Settings show={settingsMode} />
			
			{!settingsMode && user && 
			<div className={classes.cardroot}>
				<Card>
					<CardHeader style={{margin: '-6px 4px 2px 0'}} 
						action={
							<IconButton onClick={(event)=>{setAppMode("openUserMenu");setAnchorEl(event.currentTarget)}}>
								<MoreVertIcon />
							</IconButton>
						}
						titleTypographyProps={{ variant: "h6" }}
						title={(!editUser.mode)?"נתוני משתמש":"עריכת משתמש"}
					/>
					<CardContent>
						<Divider light style={{margin: '-32px 0 16px 0'}} />
						<Grid container className={classes.grid}>
							{!editUser.mode && 
							<Grid item>
								<img src={`Avatars/p${1 + (( 6*((user.gender==="m")?0:1)) + (user.id % 6))}.jpg`} width="85px" alt="" />
							</Grid>
							}
						
							{!editUser.mode ?
							<Grid item style={{margin: '4px 16px 0 0', }}>
								<React.Fragment>
									<Typography color="textPrimary" variant="h4">{user.name}</Typography>
									<Typography color="textSecondary" gutterBottom>{user.grade}</Typography>
								</React.Fragment>
							</Grid>
							:
							<Grid item style={{margin: '4px 0 0 0', }}>
										<TextField autoFocus margin="dense" id="name" label="שם התלמיד" value={editUser.name} onChange={(e)=>setEditUser({...editUser, name: e.target.value})} />
										<TextField select margin="dense" id="grade" label="כיתה" value={editUser.grade} onChange={(e)=>setEditUser({...editUser, grade: e.target.value})}>
											{user && user.avaliable_grades.map( item => 
											<MenuItem key={item} value={`כיתה ${item}`}>{item}</MenuItem>
											)}
										</TextField>
										<TextField select margin="dense" id="gender" label="מין" value={editUser.gender} onChange={(e)=>setEditUser({...editUser, gender: e.target.value})}>
											<MenuItem key='m' value="m">זכר</MenuItem>
											<MenuItem key='f' value="f">נקבה</MenuItem>
										</TextField>
							</Grid>
							}
				
						</Grid>
					</CardContent>
					{!editUser.mode ?
					<CardActions>
						<div className={classes.cardfotter1}> 
							<Button className={classes.cardfotter2} color="primary" onClick={()=>closeUserCard()}>סגור כרטסת</Button>
						</div>		
					</CardActions>						
					:
					<CardActions style={{position: 'relative'}}>
						<div > 
							<Button color="primary" onClick={()=>endEditUser(true)}>אשר</Button>
							<Button color="primary" onClick={()=>endEditUser(false)}>בטל</Button>
						</div> 
					</CardActions>
					}
				</Card>
				<Typography style={{margin: '32px 0 8px 0'}} variant="body1" component="p">ספרים משואלים:</Typography>
					{books && books.length===0 &&
					<Typography color="textPrimary" variant="h4">אין ספרים מושאלים</Typography>
					}
				<Card className={classes.booksList}>
					{books && books.map( book => 
					<ListItem button onClick={()=>{addBook(user.id, book.code, books);}} style={{backgroundColor:(book.state==='loaned')?'white':'rgb(248, 249, 250)'}} key={book.id} divider={true} >
					<React.Fragment>
						<ListItemText primaryTypographyProps={{ variant: "h6" }} primary={book.title} secondary={(book.state==="new")?"ספר חדש":(book.state==="return")?"הוחזר":`מושאל מתאריך: ${book.start_date}`} />
						<ListItemSecondaryAction>
						  <IconButton><ReturnIcon /></IconButton>
						</ListItemSecondaryAction>
					</React.Fragment>
					</ListItem>
					)}
					{(appMode==="manualBook") &&
					<ListItem key="-99" divider={false} style={{height:'85px', }}>
						<IconButton color="secondary" style={{marginRight:'-8px', }} onClick={()=>handleEscKeyEvent()}><CancelIcon /></IconButton>
						<TextField label="קוד ספר" value={bookId} autoFocus={true} />
					</ListItem>
					}
				</Card>
			
				<Menu id="userMenu" disableRestoreFocus={true} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={()=>setAnchorEl(null)}>
					<MenuItem onClick={()=>startEditUser()}><ListItemIcon style={{marginLeft:'-24px'}}><EditIcon fontSize="small" /></ListItemIcon><ListItemText primary="ערוך משתמש" /></MenuItem>
					<MenuItem onClick={()=>startDeleteUser()}><ListItemIcon style={{marginLeft:'-24px'}}><DeleteIcon fontSize="small" /></ListItemIcon><ListItemText primary="מחק משתמש" /></MenuItem>
				</Menu>
				{(appMode!=="manualBook") && false && <Fab size="medium" color="secondary" className={classes.margin} onClick={()=>setAppMode("manualBook")}><AddIcon /></Fab>}
			</div>
			}
			{(appMode!=="camera") ? 
			<Fab size="medium" color="secondary" className={classes.cameraButton} onClick={()=>setAppMode("camera")}><CameraIcon /></Fab>
			:
			<div className={classes.cameraBackground}>
				<IconButton onClick={()=>handleEscKeyEvent()}><CloseCameraIcon style={{color: "white", fontSize: 40,}} /></IconButton>
				<div className={classes.camera}>
					<BarcodeScannerComponent width="300" onUpdate={(err, result) => {if (result) getBook(result.text)}} />
				</div>
			</div>
			}
		</React.Fragment>
	);
}
