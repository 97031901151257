import React, { useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function SearchField(props) {
	const classes = useStyles();
	const [options, setOptions] = React.useState([]);
	const [inputValue, setInputValue] = React.useState('');
	const [value, setValue] = React.useState(null);

	useEffect(() => {
		var tempUsers = [];
		props.users.map(iter => iter.users.map(user=> tempUsers.push(user)));
		setValue(null);
		setOptions(tempUsers);
	}, [props.users]);
	
	return (
		<Autocomplete
			id="search-field"
			options={options}
			getOptionLabel={(option) => (option.name)}
			//getOptionSelected={(option, value) => (option===value)}
			filterOptions={(options, state) => options.filter(option => state.inputValue.split(" ").every(input => option.name.indexOf(input) !== -1)) }
			autoHighlight={false}
			clearOnEscape={true}
			handleHomeEndKeys={false}
			blurOnSelect={true}
			noOptionsText="אין תוצאות"
			inputValue = {inputValue}
			value={value}
			onChange={(event, newValue) => {
				setValue(null);
				if (newValue) props.onSelect(newValue.id);
			}}
			onInputChange={(event, newInputValue, reason) => {
				//if (reason==="reset") setValue(null);
				if (reason==="input") setInputValue(newInputValue);


			}}
			renderInput={(params) => (
				<div className={classes.search}>
					<div className={classes.searchIcon}><SearchIcon /></div>
					<InputBase
						ref={params.InputProps.ref}
						inputProps={params.inputProps}
						placeholder="חפש…"
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
						
						onFocus={(e)=>props.onStateChanged(true)}
						onBlur={(e)=>props.onStateChanged(false)}
					/>
				</div>
			)}
		/>
	);
}