import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  nameField: {
    width: "77%",
	marginRight: "3%",
  },
  genderField: {
    width: "20%",
  },
  dialogActions: {
    //marginRight: theme.spacing(1.5),
	//marginBottom: theme.spacing(1),
  },

}));

export default function NewUser(props) {
	const classes = useStyles();
	const [newUser, setNewUser] = useState({name:"", grade:"", gender:"m"});

	useEffect(() => {
		setNewUser({...newUser, "grade": props.grade})
	}, [props.grade]);
	
	return (
		<Dialog open={props.open} disableEscapeKeyDown={true} onClose={()=>props.onClose(false)}>
			<DialogTitle id="form-dialog-title">משתמש חדש</DialogTitle>
			<DialogContent>
				<DialogContentText>
					אנא הזינו את שם התלמיד (שם משפחה ושם פרטי) על מנת להוסיפו לכיתה {props.grade}.
				</DialogContentText>
				<TextField autoFocus margin="dense" id="name" label="שם התלמיד" value={newUser.name} onChange={(e)=>setNewUser({...newUser, "name": e.target.value})} className={classes.nameField} />
				<TextField select margin="dense" id="gender" label="מין" value={newUser.gender} onChange={(e)=>setNewUser({...newUser, "gender": e.target.value})} className={classes.genderField}>
					<MenuItem key='m' value="m">זכר</MenuItem>
					<MenuItem key='f' value="f">נקבה</MenuItem>
				</TextField>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button onClick={()=>props.onClose(false)} color="primary">ביטול</Button>
				<Button onClick={()=>props.onClose(true, newUser)} color="primary">הוספה</Button>
			</DialogActions>
      </Dialog>
  );
}