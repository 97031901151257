import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles((theme) => ({
	cardRoot: {
		maxWidth: 750,
		padding: theme.spacing(0),
		margin: theme.spacing(5, 'auto'),
		'& .MuiTextField-root': {
		width: '100%',
		backgroundColor: "white",
		},
	},
 
	groupTitle: {
		margin: theme.spacing(3, 0, 0, 0),
	},
	 
	textItem: {
		margin: theme.spacing(0.5, -2),
    },
  
}));

const settings = [
	{title: "הגדרות משתמשים", options:[
		{title:"הוספת משתמש חדש", desc:"יכולת הוספת משתמש לאחר בחירת כיתה מתפריט המשתמשים", key:"newUser", icon: {PersonAddIcon}},
		{title:"עריכת משתמש", key:"editUser"},
		{title:"מחיקת משתמש", key:"deleteUser"},
		{title:"הצגת היסטורית השאלות", key:"userHistory"},
	]},
	{title: "הגדרות ספרים והשאלות", options:[
		{title:"הוספת ספר חדש", desc:"יכולת הוספת משתמש לאחר בחירת כיתה מתפריט המשתמשים", key:"newBook", icon: {PersonAddIcon}},
		{title:"אפשרות השאלה ידנית", desc:"יכולת הוספת משתמש לאחר בחירת כיתה מתפריט המשתמשים", key:"manualLoan", icon: {PersonAddIcon}},
	]},
]
		
		
export default function Settings(props) {
	const classes = useStyles();
	const [checked, setChecked] = useState(['newUser']);

	useEffect(() => {
		console.log("init settings");
	}, []);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) newChecked.push(value);
		else newChecked.splice(currentIndex, 1);

		setChecked(newChecked);
	};

	return (
	<React.Fragment>
		{props.show &&
		<div className={classes.cardRoot}>
			{settings.map( group => 
			<div key={group.title}>
				<Typography className={classes.groupTitle} variant="body1" component="p">{group.title}</Typography>
				<List>
					<Paper>
						{group.options.map(option1 =>
						<ListItem key={option1.key} divider={true}>
							<ListItemIcon><PersonAddIcon /></ListItemIcon>
							<ListItemText className={classes.textItem} primary={option1.title} secondary={option1.desc} />
							<ListItemSecondaryAction>
								<Switch edge="end" onChange={handleToggle(option1.key)} checked={checked.indexOf(option1.key) !== -1} />
							</ListItemSecondaryAction>
						</ListItem>
						)}
					</Paper>
				</List>
			</div>
			)}
		</div>
		}
	</React.Fragment>

  );
}