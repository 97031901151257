import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
	const closeHandler = () => (event) => {
		if (event.key==="Escape") return;
		props.onClose(false);
	};

  return (
      <Dialog open={props.dialog} onClose={closeHandler()} >
        <DialogTitle id="alert-dialog-title">{"התראת פעולה"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.onClose(false)} color="primary">ביטול</Button>
          <Button onClick={()=>props.onClose(true)} color="primary" autoFocus>אישור</Button>
        </DialogActions>
      </Dialog>
  );
}