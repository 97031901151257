import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import G from './Globals.js';

import KeyboardEventHandler from 'react-keyboard-event-handler';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
	root: {
		width: 750,
	},
	
	nameField: {
		width: "77%",
		marginRight: "3%",
	},
	genderField: {
		width: "20%",
	},
	dialogActions: {
		//marginRight: theme.spacing(1.5),
		//marginBottom: theme.spacing(1),
	},
  
}));

export default function NewBook(props) {
	const classes = useStyles();
	const bookNameRef = useRef();
	
	const [state, setState] = useState({mode:"barcode", barcode:"scanner", barcodeId: "", bookName: ""});
	const { enqueueSnackbar } = useSnackbar();
	
	useEffect(() => {
		if (props.open) {
			setState({mode:"barcode", barcode:"scanner", barcodeId: "", bookName: ""});
		}
	}, [props.open]);
	
	const handleKeyEvent = (key, event) => {
		if (props.open) {
			if (state.mode==="barcode" && state.barcode!=="none") {
				if (event.keyCode===8) setState({...state, barcodeId: state.barcodeId.slice(0, -1)});
				else if (event.keyCode!==13) setState({...state, barcodeId: state.barcodeId+key});
				else {
					if (state.barcodeId.length>0) checkBarcode(state.barcodeId);
					setState({...state, barcodeId: ""});
				}
			} else if (event.keyCode===13 && state.mode==="bookName" && state.bookName!=="") closeDialog(true);
		}
	};
	
	const checkBarcode = (bid) => {
		setState({...state, mode: "barcodeCheck"});
		fetch(`${G.baseUrl}/books.php?op=checkBarcode&book_id=${bid}`)
			.then(res => res.json())
			.then((res)=>{
				console.log(`book name: ${state.bookName}`)
				if (res.status === "ok") state.bookName = res.bookName;
				setState({...state, mode: "bookName"});
				setTimeout(() => { bookNameRef.current.focus(); }, 0);
			})
		
	};
	
	const changeBarcodeState = (event) => {
		var input = event.target.value;
		console.log(input)
		var mode = "barcode"
		if (input!=="scanner") {
			if (input==="none") mode = "bookName";
			setTimeout(() => { bookNameRef.current.focus(); }, 0);
		}
		setState({...state, mode: mode, barcode: input, barcodeId: "", bookName: ""});
		
	};
	
	const closeDialog = (save) => {
		if (save) {
			fetch(`${G.baseUrl}/books.php?op=new&lid=${props.id}&bid=${state.barcodeId}&title=${state.bookName}`)
				.then(res => res.json())
				.then((res)=>{
					if (res.status === "ok") enqueueSnackbar("הספר הוכנס למאגר בהצלחה", { variant: 'success',});
					else {
						var variant = (res.variant)?res.variant:"error";
						enqueueSnackbar(res.errorMsg, { variant: variant,});
					}
			})
		}
		props.onClose(save);
	};
	
	return (
		<React.Fragment>
		<KeyboardEventHandler handleKeys={['numeric', 'backspace', 'enter']} handleFocusableElements={true} onKeyEvent={handleKeyEvent} />
		
		<Dialog open={props.open} disableEscapeKeyDown={true} onClose={()=>closeDialog()}>
			<DialogTitle id="form-dialog-title">הזנת ספר חדש למערכת</DialogTitle>
			<DialogContent>
				<FormControl margin="dense" disabled={state.mode==="barcodeCheck"}>
					<FormLabel style={{marginBottom: "10px",}}>ברקוד הספר: {state.barcodeId}</FormLabel>
					<RadioGroup  name="barcode" value={state.barcode} onChange={changeBarcodeState}>
						<FormControlLabel value="scanner" control={<Radio />} label="סריקת הברקוד בכריכת הספר" />
						<FormControlLabel value="manual" control={<Radio />} label="הזנה ידנית של הברקוד" />
						<FormControlLabel value="none" control={<Radio />} label="לא קיים ברקוד על גבי הספר" />
					</RadioGroup>
				</FormControl>
				<TextField inputRef={bookNameRef} disabled={state.barcode==="scanner"} id="code" style={{marginTop: "20px",}} InputLabelProps={{shrink: true,}} 
				label={(state.barcode==="manual"&&state.mode==="barcode")?"קוד הספר":"שם הספר"} 
				value={(state.barcode==="manual"&&state.mode==="barcode")?state.barcodeId:state.bookName} 
				onChange={(e)=>state.mode==="bookName" && setState({...state, bookName: e.target.value})} fullWidth />
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button onClick={()=>closeDialog()} color="primary">ביטול</Button>
				<Button disabled={state.mode!=="bookName" || state.bookName===""} onClick={()=>closeDialog(true)} color="primary">הוספה</Button>
			</DialogActions>
		</Dialog>
	  </React.Fragment>
	  
  );
}