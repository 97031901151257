import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import NewUser from './NewUser';
import G from './Globals.js';

import Drawer from '@material-ui/core/Drawer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
  list: {
	width: "100%",
  },
  details: {
	marginTop: theme.spacing(-2.5),
  },
  fab: {
	zIndex: 100,
	position: 'fixed',
	left: 15,
	bottom: 35,
  },
}));

function Users(props, ref) {
	const classes = useStyles();
	const [users, setUsers] = useState(null);
	const [addUser, setAddUser] = React.useState(null);

	useEffect(() => { loadUsers() }, []);
	
	useImperativeHandle(ref, () => ({
		reloadUsers() { loadUsers() }
	}));
	
	const closeDrawerHandler = () => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
		closeDrawer();
	};
	
	const closeDrawer = () => {
		setAddUser(null);
		props.onUsersClose();
	};
	
	const loadUsers = (queryString) => {
		var url = `${G.baseUrl}/users.php`;
		if (queryString) url += "?new=true&" + queryString;
		fetch(url)
			.then(res => res.json())
			.then(res => {
				setUsers(res);
				props.onUsersListChanged(res);
			})
	};
  
	const userSelectedHandler = (uid) => (event) => {
		event.stopPropagation();
		props.onUserSelected(uid); 
		closeDrawer();
	};

	const newUserCloseHandler = (save, newUser) => {
		const querystring = require('querystring');
		let queryString = querystring.stringify(newUser);
		if (save) loadUsers(queryString);
		setAddUser({...addUser, command:"Button"})
	};
    
	return (
		<Drawer anchor={'left'} open={props.open} disableEscapeKeyDown={true} onClose={closeDrawerHandler()}>
			<div className={classes.root}>
				{users!==null && users.map( item => 
				<Accordion key={item.grade} onClick={()=>setAddUser({command:"Button", grade: item.grade})} TransitionProps={{ unmountOnExit: true }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography className={classes.heading}>{item.grade}</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<List className={classes.list} dense={true}>
							{item.users.map( user => 
							<ListItem button key={user.id} onClick={userSelectedHandler(user.id)}>
								<ListItemText primary={user.name} />
							</ListItem>
							)}
						</List>
					</AccordionDetails>
				</Accordion>
				)}
				{(addUser!==null && addUser.command==="Button") && <Fab size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={()=>setAddUser({...addUser, command:"Dialog"})}><AddIcon /></Fab>}
				<NewUser open={addUser!=null && addUser.command==="Dialog"} grade={addUser && addUser.grade} onClose={(save, newUser)=>newUserCloseHandler(save, newUser)} />
			</div>
		</Drawer>
	);
}

export default forwardRef(Users);
